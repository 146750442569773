import React, { useState } from 'react';

export default function AUTermCN(){
	return (
		<div>
			<p><strong><u>Website Terms of Use</u></strong></p>
<p>This website is operated by CA Premium Pty Limited ABN 28 101 829 467 (&ldquo;<strong>CLSA Premium AU</strong>&rdquo;) with its registered address at Level 35 Grosvenor Place, 225 George Street, Sydney, NSW 2000, a company incorporated in Australia (&ldquo;<strong>CLSA Premium AU Website</strong>&rdquo;).</p>
<p>CLSA Premium AU is a wholly owned subsidiary of CLSA Premium Limited and holds an Australian Financial Service License (AFSL: 226602) issued by Australian Securities and Investments Commission, Australia.</p>
<p>Products and services provided through the use of our Website are subject to separate terms and conditions, which will be provided to you when you access those products or services [alternatively link the relevant agreement].</p>
<p><strong><u>Terms Variations</u></strong></p>
<p>By accessing the CLSA Premium AU Website, you agree you will be bound by the Terms of Use, notice, disclaimers and other notices on the CLSA Premium AU Website. The Terms of Use may be changed, revised or amended without any notice given to you and at sole discretion of CLSA Premium AU and will be binding on you. Unless stated otherwise, any change, revision or amendment takes effect immediately.&nbsp; You are responsible for ensuring you are familiar with the latest Terms of Use.</p>
<p><strong><u>Visitors&rsquo; Responsibility</u></strong></p>
<p>The information on this Website is not intended for distribution to, or use by, any person in any country or jurisdiction where such distribution or use would be contrary to local law or regulation. It is the responsibility of visitors to the CLSA Premium AU Website to ascertain the terms of and comply with any local law or regulation to which they are subject to.</p>
<p>You must not act in a way, or use or introduce anything (including, without limitation, any virus, worm, Trojan horse, spyware or any other similar feature) that in any way compromises, or may compromise, the Website or its underlying systems, or otherwise attempt to damage or interfere with the Website or any underlying systems.&nbsp;</p>
<p><strong><u>Third Party Website</u></strong></p>
<p>CLSA Premium AU Website may contain links to other web sites operated by third parties. CLSA Premium AU assumes no responsibility for the content or maintenance of any such third party site and makes no warranties, express or implied, with respect to its content or otherwise. CLSA Premium AU does not endorse, or approve of the operators of such third Party web sites, or the content, information, graphics and material on such third party websites. Access and use of information contain in the third party website is at your own risk.</p>
<p><strong><u>Not Investment Advice</u></strong></p>
<p>Nothing herein shall constitute or shall be deemed to constitute as providing financial or investment advice to you. Nothing on the CLSA Premium AU Website shall be taken as recommendation to invest in any of the financial products offered by CLSA Premium AU. &nbsp;</p>
<p>Without limiting the forgoing, please note that the information provided on the CLSA Premium AU website does not take into account any of your investment and financial needs, capability and objectives.</p>
<p>Prior to making any investment, you should make your independent assessment as to whether a particular investment product is suitable for your investment needs. If unsure, you should seek independent legal, tax and financial advice before deciding to proceed on making any investment.</p>
<p><strong><u>Privacy Policy</u></strong></p>
<p>Your personal information may be collected by CLSA Premium AU by visiting the CLSA Premium AU Website. To the extent that you have provided CLSA Premium AU with any personal information on CLSA Premium AU Website, CLSA Premium AU will only use these personal data in accordance with the purposes set forth in the privacy policy [link to privacy policy] and is committed to safeguarding the personal information collected.</p>
<p><strong><u>Copyright</u></strong></p>
<p>CLSA Premium AU (and our licensors) own all proprietary and intellectual property rights (including, without limitation, copyright) in the &nbsp;text, graphics, icons, photographs, videos, images, illustrations, compilations and other material on the CLSA Premium AU Website as well as the network, software, data or material that underlies or is connected to the CLSA Premium AU Website.&nbsp;</p>
<p>Without limiting the foregoing, reproduction or other use of works retrieved from CLSA Premium AU Website in all forms, media and technologies now existing or hereafter developed is subject to and protected by the Copyright Ordinance (Chapter 528, Laws of Hong Kong), the Copyright Act 1968 (Cth) (Australia) and equivalent law and treaties around the world. A single copy of material on the CLSA Premium AU Website may be downloaded and/or reprinted for non-commercial and personal use only and may not be distributed to third parties. Any downloaded or reprinted material may not be altered in any way and must contain the following copyright notice: &ldquo;&copy; CLSA Premium AU 2020 All rights reserved&rdquo; and or any other copyright notice contained on the downloaded material.</p>
<p>&nbsp;</p>
<p>No other permission is granted to you, or anyone acting on your behalf, to print, copy, reproduce, distribute, transmit, upload, download, store, display in public, alter, or modify the content of CLSA Premium AU Website. Any unauthorized downloading, re-transmission or other copying or modification of the content of the CLSA Premium AU Website or any other use than that described above is strictly prohibited by law.</p>
<p>&nbsp;</p>
<p>The name and the logo of CLSA Premium AU are proprietary trademarks of CLSA Premium AU and our licensors, the use of which without express written permission is strictly prohibited.</p>
<p>&nbsp;</p>
<p><strong><u>Disclaimer Of Liability</u></strong></p>
<p>The information and content contained in CLSA Premium AU Site have been produced and collated by CLSA Premium AU in accordance with its current practices and policies and with the benefit of information currently available to it. All reasonable efforts have been made to ensure the accuracy of the contents of the pages of the CLSA Premium AU Website at the time of preparation.</p>
<p>&nbsp;</p>
<p>The information published on the CLSA Premium AU Website is for general information only and nothing on the CLSA Premium AU Website constitutes professional advice or any binding commitment upon CLSA Premium AU in relation to the availability of services provided by CLSA Premium AU.</p>
<p>&nbsp;</p>
<p>Notwithstanding the efforts made by CLSA Premium AU to ensure the accuracy of the CLSA Premium AU Website, to the maximum extent permitted by law, CLSA Premium AU disclaims any responsibility or liability in respect to any reliance on, use of or reference to the CLSA Premium AU Website, and CLSA Premium AU does not warrant or guarantee the adequacy, accuracy or completeness of any information herein or that such information will be updated, or delivered in a timely or uninterrupted form.<strong>&nbsp;</strong></p>
<p><strong>&nbsp;</strong></p>
<p><strong>TO THE MAXIMUM EXTENT PROVIDED BY LAW, CLSA PREMIUM AU EXPRESSLY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, OF ANY KIND RELATING TO THE CLSA PREMIUM AU WEBSITE, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. TO THE EXTENT PERMISSIBLE UNDER APPLICABLE LAW, CLSA PREMIUM AU ACCEPTS NO LIABILITY FOR LOSSES OR DAMAGES WHICH MAY BE DIRECTLY OR INDIRECTLY SUSTAINED BY ANY VISITOR ON ACCESS TO THE SITE OR OTHER PERSON WHO OBTAINS ACCESS TO THE PUBLICATIONS ON THE SITE, OR FOR ANY CLAIM BY ANY THIRD PARTY.&nbsp;</strong></p>
<p><strong>&nbsp;</strong>You agree that, to the extent permissible by law, this exclusion of liability is comprehensive and applies to all damages of any kind including without limitation direct, indirect, compensatory, special, multiple, incidental, punitive and consequential.</p>
<p>&nbsp;<strong><u>Severability</u></strong></p>
<p>In the event where any provisions of the Terms of Use is held unenforceable then that provision or, if permissible, the relevant part of it, will be severed in respect of that jurisdiction. The remainder of the Terms of Use will have full force and effect and the validity or enforceability of that provision in any other jurisdiction is not affected.</p>
<p><strong><u>Governing law and jurisdiction</u></strong></p>
<p>By accessing the CLSA Premium AU Website and any of its content, you agree that any disputes shall be governed by the Australia laws and to the non-exclusive jurisdiction of the Australia courts in connection with any suit, action or proceeding arising out of or in connection with the use of the CLSA Premium AU Website.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>

		</div>
	)
}