import _isArray from "lodash/isArray";
import _map from "lodash/map";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";
import Layout from "../components/Layout";
import Title from "../components/Title";
import useTranslate from "../hooks/useTranslate";

const StyledList = styled.ol`
  counter-reset: list;

  > li {
    list-style: none;
  }

  > li::before {
    counter-increment: list;
    display: inline-block;
    content: ${({ level }) =>
      level % 2 === 0
        ? 'counter(list, lower-alpha) "."'
        : '"(" counter(list, lower-alpha) ")"'};
    margin-right: 0.25rem;
    width: 1.5rem;
  }
`;

const DocumentsList = ({ className = "", items = [], level = 1, ...props }) => (
  <StyledList
    className={`documents-list ${className}`}
    level={level}
    {...props}
  >
    {_map(items, ({ label, value } = {}, key) =>
      _isArray(value) ? (
        <li key={Number(key)}>
          {label}
          <DocumentsList items={value} level={level + 1} />
        </li>
      ) : (
        <li key={Number(key)}>
          <a href={value} rel="noopener noreferrer" target="_blank">
            {label}
          </a>
        </li>
      )
    )}
  </StyledList>
);

const DocumentsOnDisplayPage = ({ className = "", ...props }) => {
  const {
    config: {
      intro: introConfig = {},
      documents: documentsConfig,
      helmet: helmetProps = {},
    },
  } = useTranslate({ configKey: "documents-on-display" });

  return (
    <Layout
      headerProps={{ bg: "dark" }}
      helmetProps={helmetProps}
      showBanner={false}
    >
      <Container
        className={`documents-on-display-page mt-4 pt-5 ${className}`}
        {...props}
      >
        {introConfig && (
          <Row className="intro mb-5">
            <Col>
              <Title>{introConfig.title}</Title>
            </Col>
          </Row>
        )}
        {documentsConfig &&
          ((documents) => (
            <Row className="intro my-5">
              <Col>
                <DocumentsList items={documents} />
              </Col>
            </Row>
          ))(documentsConfig)}
      </Container>
    </Layout>
  );
};

export default DocumentsOnDisplayPage;
